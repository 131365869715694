<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader
            :title="headercontent.title"
            :addlink="headercontent.addlink"
          />
        </v-col>

        <v-row>
          <v-col md="4">
            <v-select
              :items="allTrainings.results.list"
              v-model="subject"
              label="Subject"
              item-text="trainingTitle"
              item-value="slug"
              outlined
            ></v-select>
          </v-col>
          <v-col md="4">
            <v-btn
              :disabled="!valid"
              class="submitbutton btn-dark mt-1"
              style="height: 50px"
              @click="validate"
              :loading="filterLoading"
            >
              Filter Course
            </v-btn>
          </v-col>
        </v-row>

        <v-col md="12">
          <v-simple-table class="table">
            <template v-slot:default v-if="!loading">
              <thead>
                <tr>
                  <th class="text-left">S/N</th>
                  <th class="text-left">Fullname</th>
                  <th class="text-left">Email</th>
                  <th class="text-left">Phone</th>
                  <th class="text-left">Course</th>
                  <th class="text-left">Enquiry Type</th>
                  <th class="text-left">Payment Status</th>
                  <th class="text-left">Date</th>
                </tr>
              </thead>
              <tbody v-if="!loading">
                <tr
                  v-for="(item, index) in allTrainingRegistrations.results"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ item.fullName }}
                  </td>
                  <td>
                    {{ item.email }}
                  </td>
                  <td>
                    {{ item.phone }}
                  </td>

                  <td>
                    {{ item.subject }}
                  </td>
                  <td>
                    {{ item.inquiryType ? item.inquiryType : "General" }}
                  </td>
                  <td>
                    {{ item.inquiryType ? "Paid" : "Not Paid" }}
                  </td>

                  <td>
                    {{ item.createdAt | formatDate }}

                    <a
                      :href="`https://www.necojobs.com.np/training/${item.subject}`"
                      class="ml-10 primary"
                      style="
                        color: #fff;
                        display: inline-block;
                        padding: 4px 10px;
                        border-radius: 5px;
                      "
                      target="_blank"
                      small
                      >View</a
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-if="loading">
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
          </div>
          <ul class="pagination-button" v-if="!loading">
            <li>
              Page :
              <span v-if="allTrainingRegistrations.pagination.next">{{
                (allTrainingRegistrations.pagination.next - 1) * this.limit
              }}</span
              ><span v-if="!allTrainingRegistrations.pagination.total">{{
                allTrainingRegistrations.pagination.total
              }}</span>
              <span v-if="allTrainingRegistrations.pagination.next"> -</span>
              {{ allTrainingRegistrations.pagination.total }}
            </li>
            <li>
              <div>
                <v-btn
                  v-if="allTrainingRegistrations.pagination.previous"
                  @click="
                    prevPage(allTrainingRegistrations.pagination.previous)
                  "
                  class="btn-dark"
                  >Prev</v-btn
                >

                <v-btn
                  v-if="allTrainingRegistrations.pagination.next"
                  @click="nextPage(allTrainingRegistrations.pagination.next)"
                  class="btn-dark"
                  >Next</v-btn
                >
              </div>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Training",
  components: { PageHeader },
  data: () => ({
    headercontent: {
      title: "Trainings Inquiry List",
      // addlink: "/dashboard/training/add",
    },
    title: "",
    adsposition: "",
    valid: true,
    limit: 100,
    skip: 1,
    activelist: [
      { name: "Active", status: true },
      { name: "Deactive", status: false },
    ],
    status: true,
    loading: true,
    subjects: [],
    subject: undefined,
    filterLoading: false,
  }),
  computed: {
    ...mapGetters(["allTrainingRegistrations", "allTrainings"]),
  },
  methods: {
    ...mapActions(["getRegistrations", "filterListing", "getTrainingListing"]),

    async validate() {
      this.filterLoading = true;
      await this.getRegistrations({
        limit: this.limit,
        skip: this.skip,
        status: this.status,
        subject: this.subject,
      });
      this.filterLoading = false;
    },
    async nextPage(data) {
      await this.getRegistrations({
        limit: this.limit,
        skip: data,
        status: this.status,
        subject: this.subject,
      });
    },
    async prevPage(data) {
      await this.getRegistrations({
        limit: this.limit,
        skip: data,
        status: this.status,
        subject: this.subject,
      });
    },
  },
  async created() {
    await this.getRegistrations({
      limit: this.limit,
      skip: this.skip,
      status: this.status,
    });
    await this.filterListing({
      limit: this.limit,
      skip: this.skip,
      status: this.status,
    });

    this.getTrainingListing({
      limit: this.limit,
      skip: this.skip,
      status: true,
    });
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.form-section {
  padding: 15px 0;
  .col {
    padding: 0 10px;
  }
}
.filtertitle {
  margin-bottom: 10px;
}
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  margin-top: -10px;
}
img {
  width: 100px;
}

.table {
  box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.1);

  thead {
    border-bottom: 2px solid $menucolor;
    background: $tagcolor;
  }
  th {
    font-size: $normal_lfont !important;
    color: $white !important;
  }
}
.deactive,
.active {
  display: block;
  padding: 2px;
  width: 100px;
  text-align: center;
  color: $white;
  border-radius: 20px;
}
.deactive {
  background: red;
}
.active {
  background: green;
}
.active {
  background: green;
}
.pagination-button {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: space-between;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  .btn-dark {
    margin-top: 0;
    margin-left: 10px;
  }
}
.table-heading,
.pagination-button {
  border: 1px solid $menucolor;
  margin-bottom: 0;
  padding: 10px;
  align-items: center;
}
.icons-action {
  margin: 10px;
  font-size: $iconfont;
}
</style>
